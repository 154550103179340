<template>
  <Component
    :is="getComponent(data.style)"
    v-bind="computedProps"
  />
</template>

<script setup lang="ts">
import { defineAsyncComponent } from 'vue';
import { type Props as BannerProps } from '~/components/BaseBannerFullBg.vue';

interface Props extends BannerProps {
  align?: 'center' | 'left'
}

type BannerStyle = 'editorial' | 'editorial-centered' | 'title-top' | 'full-width';

const props = defineProps({
  data: {
    required: true,
    type: Object,
  },
});

const data = props?.data || {};
const computedProps: Props = {
  title: data.title,
  subtitle: data.subtitle,
  ctaText: data.cta?.button_text,
  ctaLink: data.cta?.button_link,
  imageSrc: data.image?.original?.src,
  imageAlt: data.image?.title,
  align: data.style === 'editorial' ? 'left' : 'center' as Props['align'],
};

function getComponent (style: BannerStyle) {
  const mapping: Record<BannerStyle, any> = {
    'full-width': defineAsyncComponent(() => import('~/components/BaseBannerFullBg.vue')),
    'title-top': defineAsyncComponent(() => import('~/components/BaseBannerFullBgTopTitle.vue')),
    editorial: defineAsyncComponent(() => import('~/components/BaseBannerImageSide.vue')),
    'editorial-centered': defineAsyncComponent(() => import('~/components/BaseBannerImageSide.vue')),
  };

  return mapping[style] || undefined;
}
</script>
